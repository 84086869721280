import React, { Component } from 'react';
import detectBrowserLanguage from 'detect-browser-language'
import {Helmet} from "react-helmet";

import './style/app.sass';
import Navigation from './stack/Navigation';
import WOW from 'wowjs';


// import logoLuxu from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/logo-luxu.svg';
// import secondPaint from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-second-paint.png';
// import thirdPaint from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-third-paint.png';
// import fourthPaint from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-fourth-paint.png';
// import fifthPaint from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-fifth-paint-iphone.png';
// import thirdIconCard from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-third-icon-card.png';
// import thirdIconCast from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-third-icon-cast.png';
// import thirdIconFilm from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-third-icon-film.png';
// import logoMion from 'https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/logo-mion.svg';


class App extends Component {
  render() {
    const wow = new WOW.WOW();
    wow.init();

    window.addEventListener(
      "touchmove",
      function(event) {
          if (event.scale !== 1) {
              event.preventDefault();
          }
      },
      { passive: false }
    );

    var lang = detectBrowserLanguage();
    var cn = "zh-CN";
    // { lang == cn ? 'a' : 'b' }
    return (
      
      <main className="app o-content" className = { lang == cn ? 'cn-typo' : '' } id="app">
        <header className="header wow fadeIn" >
          < Navigation />
        </header>
        <Helmet>
          <title>{ lang == cn ? 'MION 好眠 - 健康生活' : 'MION' }</title>
        </Helmet>
        <section className="intro" id="intro">
          <div className="intro-main-wrapper">
            <div className={ lang == cn ? 'intro-main-cn intro-main m-container o-container' : 'intro-main m-container o-container' }>
              <div className="o-content">
                <div className="o-grid-row">
                  <div data-o-grid-colspan="6 S12 M6 XL3">
                  <h1 className={ lang == cn ? 'h1-cn wow fadeInUp' : 'wow fadeInUp' }> { lang == cn ? '同你一起渡过' : 'Hej, It\'s time to sleep' } <br/> { lang == cn ? '生命中三分之一的时光' : '' }
                  </h1>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="intro-bottom m-container o-container">
              <div className="o-content">
                <div className="intro-bottom-container o-grid-row">
                  <div className="bottom-subinfo-1" data-o-grid-colspan="4 S12 M6 XL3">
                    <h3 className="wow fadeInUp" data-wow-delay="0.2s">{ lang == cn ? '辅助与调理' : 'Sleep Therapy' }</h3>
                    <div className="o-grid-row">
                      <div data-o-grid-colspan="10">
                        <p  className="wow fadeInUp" data-wow-delay="0.3s">{ lang == cn ? '使用 Mion 独创的睡眠卡片进行睡眠调理，或是使用睡眠电台与影院，帮你快速入眠。' : 'Use Mion\'s original Sleep Card for sleep therapy, or use the Sleep Cast and Film to help you get to sleep quickly.' }</p>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-subinfo-2" data-o-grid-colspan="4 S12 M6 XL3">
                    <h3 className="wow fadeInUp" data-wow-delay="0.4s">{ lang == cn ? '追踪与分析' : 'Tracking and Analysis' }</h3>
                    <div className="o-grid-row">
                      <div data-o-grid-colspan="10">
                        <p  className="wow fadeInUp" data-wow-delay="0.5s">{ lang == cn ? '智能的睡眠追踪与分析可感知睡眠环境，使得算法不断地优化追踪结果，帮你掌控睡眠。' : 'Intelligent sleep tracking and analysis senses the sleep environment, allowing algorithms to constantly optimize tracking results and help you control your sleep.' }</p>
                      </div>
                    </div>
                    
                    
                  </div>
                  <div className="bottom-subinfo-3" data-o-grid-colspan="4 S12 M6 XL3">

                    <div className="o-grid-row">
                      <div data-o-grid-colspan="10">
                        <table  className="wow fadeInUp" data-wow-delay="0.6s">
                          <tbody>
                            <tr>
                              <th><span className="th-title">Sponsor:</span></th>
                              <th rowSpan="2"><img src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/logo-luxu.svg' className="luxu-logo" alt="logo" /></th>
                            </tr>
                            <tr>
                              <th>Series of Trilogy</th>
                            </tr>
                          </tbody>
                        </table>
                      </div></div>
                  </div>
                  <div className="bottom-subinfo-4" data-o-grid-colspan="4 S12 M6 XL3">

                    <a className={ lang == cn ? 'download wow fadeInUp hide' : 'download wow fadeInUp' } href='https://apps.apple.com/cn/app/id1401308399' target="_blank"  data-wow-delay="0.7s"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="background-overlay"></div>
          <video autoPlay loop preload="auto" playsInline muted id="mobile-video">
            <source src="https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/video/intro.mp4" type="video/mp4"/>
          </video>

          <iframe id="m-background-GL" title="Mion GL" width="420" height="345" src="https://mionapp.com/glsl" frameBorder="0" allowFullScreen=""></iframe>
        </section>

        <section className="section-fix-padding" id="second">
          <div className="second-wrapper">
            <div className="paint">
              <img  className="wow fadeInUp" data-wow-delay="0.0s" src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-second-paint.png' alt=""/>
            </div>
            <div className="second-context">
              <h2 className={ lang == cn ? 'h2-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.1s">{ lang == cn ? '使用 Mion 就够了' : 'This is the only Sleep App you need' }</h2>
              <p className={ lang == cn ? 'p-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.2s">{ lang == cn ? '通过睡眠辅助和调理来舒缓与引导使用者的思绪，让使用者从紧张和焦虑的状态中缓缓进入睡眠。我们在调查中发现大部分人睡眠前最后的动作是放下手机，所以我们决定以手机为载体重新探索睡眠。Mion 还包含了睡眠追踪及分析的功能，从入睡到唤醒，提供全方位的睡眠体验。' : 'Through sleep treatment, Mion relieves and guides the user\'s emotions, allowing users to slowly enter a comfortable state from nervousness and anxiety. We found that most people\'s last action before sleep was to put down the phone, so we decided to use the phone as a carrier to re-explore sleep. And thus really change the quality of sleep.' } </p>
            </div>
          </div>
        </section>

        <section className="section-fix-padding" id="third">
          <div className="third-wrapper">
            <div className="paint">
              <img  className="wow fadeInUp" data-wow-delay="0.0s" src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-third-paint.png' alt=""/>
            </div>
            <div className="third-context">
              <h2 className={ lang == cn ? 'h2-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.1s">{ lang == cn ? '氛围，很重要' : 'Atmosphere is the key' }</h2>
              <p className={ lang == cn ? 'p-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.2s">{ lang == cn ? '从想要入眠时却思绪纷飞到内心平静，需要怎么做？ Mion 包含了持续更新的音乐和影片来辅助睡眠，这些辅助形式包含多种使人能够放松、平静的意象，通过这些意象的演绎，创造适合睡眠的温馨氛围。' : 'What do you do when you want to fall asleep quickly but toss and turn? Mion includes continuously updated music and movies to help sleep. These contents contain a variety of images that allow people to relax and calm.  The interpretation of these images can create a warm atmosphere for you to sleep.' } </p>
              <div className="icons">
                <div className="card wow fadeInUp" data-wow-delay="0.3s">
                  <div className="icon"><img src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-third-icon-card.png' alt=""/></div>
                  <div className="iconName">Card</div>
                </div>
                <div className="cast wow fadeInUp" data-wow-delay="0.4s">
                  <div className="icon"><img src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-third-icon-cast.png' alt=""/></div>
                  <div className="iconName">Cast</div>
                </div>
                <div className="film wow fadeInUp" data-wow-delay="0.5s">
                  <div className="icon"><img src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-third-icon-film.png'alt=""/></div>
                  <div className="iconName">Film</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-fix-padding" id="fourth">
          <div className="fourth-wrapper">
            <div className="paint">
              <img  className="wow fadeInUp" data-wow-delay="0.0s" src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-fourth-paint.png' alt=""/>
            </div>
            <div className="fourth-context">
              <h2 className={ lang == cn ? 'h2-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.1s">{ lang == cn ? '让睡眠多一点智能' : 'Make sleep analysis smarter' }</h2>
              <p className={ lang == cn ? 'p-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.2s">{ lang == cn ? 'Mion AI 的睡眠分析算法可感知你的睡眠环境，自动适应床垫的软硬度甚至是你的睡眠行为。睡眠分析的精度也会随着数据丰富程度自动地进行调整，除此以外，Mion 通过算法还可以精确地计算出你的入睡时间。' : 'Mion AI\'s sleep analysis algorithm senses your sleeping environment and automatically adapts to the softness of your mattress and even your sleep behavior. The accuracy of sleep analysis is also automatically adjusted with the amount of data. In addition, Mion can accurately calculate your fall-asleep time through the algorithm.' }</p>
            </div>
          </div>
        </section>

        <section className="section-fix-padding" id="fifth">
          <div className="fifth-wrapper">
            <div className="paint">
              <img className="wow fadeInUp" data-wow-delay="0.0s" src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/section-fifth-paint-iphone.png' alt=""/>
            </div>
            <div className="fifth-context">
              <h2 className={ lang == cn ? 'h2-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.1s">{ lang == cn ? '好眠是身心健康的基础' : 'Good sleep matters' }</h2>
              <p className={ lang == cn ? 'p-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.2s">{ lang == cn ? '现代生活的方方面面影响了我们的生活节奏，改变了我们的睡眠习惯。差旅、熬夜工作、娱乐……等等各种因素不断地压缩着我们的睡眠时间和质量，使得身心健康的平衡被打破。正因如此，越来越多人意识到拥有良好睡眠的重要性，Mion 是一个睡眠好帮手，能够改善你的入睡质量、帮助养成良好的睡眠习惯。' : 'Every aspect of modern life affects the pace of our lives and also changes our sleep habits. Travel, work until late at night, entertainment, etc. continue to compress our sleep time and quality. The balance of body and mind is broken, chaos comes one after another. More and more people are aware of the importance of having a good night\'s sleep. Mion is a good sleep helper that can improve your sleep quality and help you develop good sleep habits.' }</p>
            </div>
          </div>
        </section>

        <section id="outro">
          <video autoPlay loop preload="auto" playsInline muted id="outro-video">
            <source src="https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/video/outro.mp4" type="video/mp4"/>
          </video>
          <div className="outro-wrap">
            <div className="outro-context">
              <h2 className={ lang == cn ? 'h2-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.1s">{ lang == cn ? '不断更新的精彩内容' : 'Constantly updated content' }</h2>
              <p className={ lang == cn ? 'p-cn wow fadeInUp' : 'wow fadeInUp' } data-wow-delay="0.2s">{ lang == cn ? 'Mion 的制作者在各地进行影音采集，将温暖、使人舒适放松的气氛透过小小的移动设备传递出来，这是一件神奇和美妙的事情。所有你能够在应用中看到的内容，都是根据 Mion 独有的视听语言进行创作的，今后还会有越来越多的创作者参与到我们的内容制作中来。' : 'It is a magical and wonderful thing for Mion\'s creators to capture audio and video from all over the world, passing it warm and comfort through small mobile devices. All the content you can see in the app is created according to Mion\'s unique audiovisual language, and more and more creators will participate in our content creation in the future.' }</p>
              <p><a href="https://apps.apple.com/cn/app/id1401308399"  target="_blank" className={ lang == cn ? 'download-bottom wow fadeInUp hide' : 'download-bottom wow fadeInUp' } data-wow-delay="0.3s"></a></p>
            
            </div>
          </div>
          
        </section>

        <footer>
          <div className="footer-wrapper">
            <div className="left wow fadeInUp" data-wow-delay="0.0s">
              <img  src='https://mion-user-files.oss-cn-hongkong.aliyuncs.com/website/images/logo-mion.svg' alt="logo" />
              <p>
                Make a good dream
              </p>
              <p  className="split-line">------</p>
              <p>
                Design by Excited Cosmos
              </p>
              <p>
                Made with ♥
                
              </p>
              <p></p>
              { lang == cn ? <p>Copyright ©2019 Mion. All Rights Reserved.</p> : '' }
              { lang == cn ? <p><a className="beian" href="http://beian.miit.gov.cn/">蜀ICP备18022389号-1</a></p> : '' }

              
            </div>
            <div className="middle wow fadeInUp" data-wow-delay="0.1s">
              <ul>
                <li><a className={ lang == cn ? 'hide' : '' } target="_blank" href={ lang == cn ? 'http://misc.mionapp.com/item/about.html' : 'http://misc.mionapp.com/item/about-en.html' }>{ lang == cn ? '关于' : 'About' }</a></li>
                <li><a target="_blank" href={ lang == cn ? 'http://misc.mionapp.com/item/log.html' : 'http://misc.mionapp.com/item/log-en.html' }>{ lang == cn ? '更新日志' : 'Update Log' }</a></li>
                <li><a className={ lang == cn ? 'hide' : '' } target="_blank" href={ lang == cn ? 'http://misc.mionapp.com/item/term.html' : 'http://misc.mionapp.com/item/term-en.html' }>{ lang == cn ? '服务条款' : 'Terms of Service' }</a></li>
                <li><a className={ lang == cn ? 'hide' : '' } target="_blank" href={ lang == cn ? 'http://misc.mionapp.com/item/privacy.html' : 'http://misc.mionapp.com/item/privacy-en.html' }>{ lang == cn ? '隐私政策' : 'Privacy Policy' }</a></li>
              </ul>
            </div>
            <div className="right wow fadeInUp" data-wow-delay="0.2s">
              <ul>
                <li><a className={ lang == cn ? 'hide' : '' } target="_blank" href={ lang == cn ? 'http://misc.mionapp.com/item/help.html' : 'http://misc.mionapp.com/item/help-en.html' }>{ lang == cn ? '' : 'Help' }</a></li>
                <li><a target="_blank" href="mailto:service@mionapp.com">{ lang == cn ? '与 Mion 联系' : 'Contact' }</a></li>
                <li><a  target="_blank" className={ lang == cn ? ' mion-support hide' : 'mion-support' } href={ lang == cn ? 'http://misc.mionapp.com/item/mion-support.html' : 'http://misc.mionapp.com/item/mion-support-en.html' }>MION SUPPORT</a></li>
                <li><a target="_blank" href="https://www.instagram.com/mionsleep/">Instagram</a></li>
                <li><a target="_blank" href="https://twitter.com/mionsleep">Twitter</a></li>
              </ul>
            </div>
          </div>
          
        </footer>

      </main>
    );
  }
}

export default App;
