import React, { Component } from 'react';
import detectBrowserLanguage from 'detect-browser-language'
import '../style/navigation.sass';
import logo from '../images/logo.svg';
import logoMion from '../images/logo-mion.svg';
import cornerMark from '../images/corner-mark.svg';
class Navigation extends Component {
    render() {
        var lang = detectBrowserLanguage();
        var cn = "zh-CN";
        // { lang == cn ? 'a' : 'b' }
        return (
          <nav className="navigation">
            <div className="navigation-wrapper o-container">
              <div className="logo-wrapper">
                <img src={logo} className="mion-logo" alt="logo" />
              </div>
              <div className="navigation-links-wrapper m-container o-container">
                <ul  className={ lang == cn ? 'navigation-links hide' : 'navigation-links' }>
                  <li className=""><a target="_blank" href="https://apps.apple.com/us/app/mion-sleep/id1401308399">{ lang == cn ? '下载' : 'Download' }</a></li>
                  <li><a target="_blank" href={ lang == cn ? 'http://misc.mionapp.com/item/help.html' : 'http://misc.mionapp.com/item/help-en.html' }>{ lang == cn ? '帮助' : 'Help' }</a></li>
                </ul>
              </div>
              <div className="text-logo-wrapper">
                <img src={logoMion} className="mion-text-logo" alt="logo" />

              </div>

              <div className="corner-mark">
                <img src={cornerMark} className="corner-mark-image" alt="All rights reserved." />
              </div>
            </div>

          </nav>    
        );
      }
}

export default Navigation;